<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Despesas</h2>
              <p>
                Estas são as suas despesas. Controle bem as suas finanças  🤑
              </p>
            </div>
          </div>
          <button class="btn btn-danger pull-right ml-5 action-income" type="button" @click="createTransaction('create-transaction-modal')"><span><i class="mdi mdi-plus-circle-outline"></i></span> Lançar despesa </button>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-sm-9" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Filtro</label>
          </div>
        </div>
        <div v-for="(filter, n) in filters" class="form-row" :key="n">
          <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <select-input
              v-model="filters[n].field"
              :reduce="(field) => field.value"
              :options="fields"
              :clearable="true"
            />
          </div>
          <div class="form-group col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <select-input
              v-if="filters[n].field == 'all'"
              multiple
              :reduce="(field) => field.value"
              label="label"
              v-model="filters[n].values"
              :options="filterAll"
              :clearable="true"
              @input="filterRequest"
            />
            <select-input
              v-else-if="filters[n].field == 'card_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="cards"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'account_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="wallets"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'category'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="categories"
              :clearable="true"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="Selecione um campo"
              disabled
            />
          </div>
          <div class="form-group col-lg-1 hide">
            <button
              @click="removeFilter(n)"
              class="btn btn-danger btn-sm"
              style="height: 40px;"
            >
              <i class="fas fa-trash color-white"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <br>
        <div class="input-group mb-3" style="margin-top:5px;">
          <date-range-picker
            class="form-control mr-sm-2"
            value
            :options="optionsDate"
            @change="changeDate"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-left hide">
        <button @click="addFilter" type="button" class="btn btn-primary">
          <i class="fas fa-plus"></i> Adicionar Filtro
        </button>
      </div>
      <div class="col-sm-12 text-right">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-4 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4>Despesa total</h4>
          </div>
          <div class="card-body">
            <section class="text-center mt-15" v-if="isLoading">
              <div class="shimmerBG title-line" style="width: 60%; margin-top:0px; height: 40px"></div>
              <hr>
              <div class="chart-insights text-left">
                <p>Recorrentes</p>
                <div class="shimmerBG title-line" style="width: 40%; margin-top:0px; height: 30px"></div>
              </div>
            </section>
            <section class="text-center mt-15" v-else>
              <h1 class="reports-expenses text-left font-w-100 ng-binding">{{this.report.transactions.report.total_expense | currency}}</h1>
              <hr>
              <div class="chart-insights text-left">
                <p>Recorrentes</p>
                <h4><strong class="font-w-400">{{this.report.transactions.report.bill_expense | currency}}</strong></h4>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
      <div class="col-sm-8 col-lg-8 col-md-8">
        <div class="card">
          <div
            class="
              card-body
              d-flex
              align-items-center
              justify-content-center
              no-padding
            "
          >
            <h4 class="text-left block" style="float: left">
              Top categorias
            </h4>
            <div>
              <div style="clear: both"></div>
            </div>
            <div v-if="isLoading" class="row">
              <div class="col-lg-7">
                <div class="shimmerBG" style="width: 150px; height: 150px; border-radius: 50%;margin: 0 auto;"></div>
              </div>
              <div class="col-lg-5">
                <br>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 40px"></div>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 40px"></div>
                <div class="shimmerBG title-line" style="width: 90%; margin-top:15px; height: 40px"></div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-lg-12" v-if="this.report.transactions.report.categories.length == 0">
                <div class="row ng-scope">
                  <img :src="`assets/images/transaction.png`" class="nothing-image" style="width:15.5%; !important">
                  <h3 class="nothing-text">Nenhum lançamento</h3>
                </div>
              </div>
              <div v-else class="col-lg-7 col-sm-7 col-md-7">
                <v-chart class="chart" :option="optionCat" />
              </div>
              <div class="col-lg-5 col-sm-5 col-md-5">
                <div class="chart-legend chart-legend--vertical" v-for="category in this.report.transactions.report.categories" :key="category.id">
                  <span class="chart-legend-item relative">
                    <div class="icon-category-overview" :style="`background: ${category.color}`">
                      <img :src="`assets/images/icons/png/${category.icon}.png`" />
                    </div>
                    {{category.name}}
                    <strong style="position: absolute; right: 10px"
                      >{{category.total | currency}}</strong
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="cashflow-print" @click="print"><i class="fa fa-print hide"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="row hide" ng-class="CashFlow.add == true ? 'show' : 'hide' ">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Valor</label>
                        <input class="form-control currency ng-pristine ng-untouched ng-valid ng-empty" data-thousands="." data-decimal="," placeholder="0,00" name="value" ng-model="CashFlow.event.value">
                      </div>
                    
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Tipo</label>
                        <select class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="type" ng-model="CashFlow.event.type_event"><option value="? undefined:undefined ?" selected="selected"></option>
                          <option value="income">Entrada</option>
                          <option value="expense">Saída</option>
                        </select>
                      </div>
                      <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4">
                        <label>Categoria</label>
                        <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="description" placeholder="Categoria" ng-model="CashFlow.event.description">
                      </div>
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                        <label>Data</label>
                        <input type="text" class="form-control datepicker-current ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" required="" past="true" placeholder="Data" name="due_date" ng-model="CashFlow.event.date">
                      </div>
                      <div class="col-sm-2 col-sm-4 col-lg-2 col-xs-2 text-right">
                        <br>
                        <button class="btn btn-primary btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.renderVar('')"><i class="fa fa-check"></i></button>
                        <button class="btn btn-danger btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.hideAdd()"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="display-4">Transações</h3>
              <hr>
              <div class="row ng-scope" v-if="report.transactions.transactions.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="report.transactions.transactions">
                <li class="list-group-item ng-scope" v-for="(item, i) in report.transactions.transactions" :key="i">
                  <div class="row">
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-6 text-right">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12">
                      <strong class="ng-binding" v-if="item.account">{{item.account.name }}</strong>
                      <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 m-p-top">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category ? item.category.color : '#ccc'}`">
                          <img v-if="item.category" :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                        </div>
                        &nbsp;{{item.category ? item.category.name : 'Sem categoria'}}
                      </span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                      <i class="fa fa-credit-card" v-if="item.card"></i>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <button type="button" class="btn-edit-category" @click="editTransaction('edit-transaction-modal', item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" class="btn-remove-category" @click="removeTransaction(item)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col-sm-12 relative" v-if="item.description || item.automatic || item.provider" style="min-height: 28px;">
                      <hr class="hr-transactions">
                      <span class="ng-binding transaction-note" v-if="item.description">
                        <strong>Descrição</strong> {{item.description }}
                      </span>
                      <span class="ng-binding transaction-note" v-if="item.provider">
                        <strong>Fornecedor</strong> {{ item.provider.name }}
                      </span>
                      <span class="label label-success " style="right: 15px; top: 15px; font-size: 10px; font-weight: 100; !important; position: absolute;" v-if="item.automatic">Automática</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import SelectInput from '@/components/SelectInput.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import VChart, { THEME_KEY } from "vue-echarts";
import Swal from 'sweetalert2';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    SelectInput,
    VChart,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      cards: [],
      categories: [],
      wallets: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {
        transactions: {
          transactions: []
        }
      },
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
      isLoadingWallet: true,
      isLoadingCards: true,
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      optionCat: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              '</h3> <span class="text-left" style="display:block; color: #000 !important;">' +
              params.percent +
              "%</span></span>";

            return template;
          },
        },
        toolbox: {
          show: false,
        },
        color: ["#f62d51", "#dddddd", "#ffbc34"],
        calculable: true,
        series: [
          {
            name: "Top",
            type: "pie",
            roseType: "radius",
            radius: [30, 70],
            center: ["50%", "50%"],
            data: [],
          },
        ],
      },
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    console.log('Mês', this.monthStart);
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');
    this.request();

    AppService.getCards({
      from: this.from,
      to: this.to,
    }).then(
      (response) => {
        console.log('Cards');
        console.log(response);
        this.cards = response.cards;
        this.isLoadingCards = false;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getWallets({}).then(
      (response) => {
        this.isLoadingWallet = false;
        this.wallets = response.wallets;
        console.log(response);
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getCategories({}).then(
      (response) => {
        this.categories = response.categories;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );
    this.$root.$on('register.transaction', this.request);
  },
  methods: {
    deleteTransaction(id) {
      AppService.deleteTransaction(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Transação removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeTransaction(item) {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover essa transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteTransaction(item.id);
        }
      });
    },
    createTransaction(id) {
      const data = {
        isCard: false,
        type: 'expense',
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice: null,
        account: null,
        date: moment(new Date()).format('DD/MM/YYYY'),
        check_sub: true,
      };
      this.$root.$emit('show.modal', id, data);
    },
    editTransaction(id, item) {
      let data      = item;
      data.date     = moment(data.transaction_date, "YYYY-MM-DD").format('DD/MM/YYYY');
      data.value    = item.amount;
      data.note     = item.description;
      data.parent   = item.category.parent;
      this.$root.$emit('show.modal', id, data);
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      AppService.getInfo({
        from: this.from,
        to: this.to,
        type: 'expense',
        no_card: true,
        filter: this.filters
      }).then(
        (response) => {
          this.isLoading = false;
          this.report = response;
          this.optionCat.series[0].data = [];
          this.optionCat.color = response.transactions.report.colors_category;
          response.transactions.report.categories.forEach(element => {
            this.optionCat.series[0].data.push(
              {
                value: element.percent,
                name: element.name,
                label: {
                  formatter: function (params) {
                    console.log(params);
                    var template = '{Showers|}';
                    return template;
                  },
                  backgroundColor: element.color ? element.color : '#18b272',
                  borderColor: "#fff",
                  borderWidth: 1,
                  borderRadius: 30,
                  padding: 8,
                  rich: {
                    backgroundColor: '#ccc',
                    Showers: {
                      height: 20,
                      align: "center",
                      backgroundColor: {
                        image: `assets/images/icons/png/${element.icon}.png`,
                      },
                    },
                  },
                },
              }
            );
          });
          console.log('Expense');
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
</style>
